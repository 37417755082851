import React,{useState} from 'react'
import { FormModal } from "./ModalForm";

export default function Apply() {
  const [isFormModalOpen, setFormModalOpen] = useState(false);
  const handleFormModalOpen = () => {
    setFormModalOpen(true)
  }

  const closeFormModal = () => {
    setFormModalOpen(false)
  }
  return (
    <section className={` flex flex-row justify-center items-center h-auto bg-orange`}>
          <article className='container flex flex-col items-center pb-8 w-full'>
              <span className={`text-white text-[36px]  pt-10 text-center font-bold`}>Ready to shape the future of investment management?</span>
              <span className={`text-white text-[36px]  text-center `}>Apply to join the Alpha Hub Founder's Circle Program today!</span>
              <div className='max-w-[1000px] h-auto text-left text-s'>
                  <p>
                  Are you ready to shape the future of investment technology? Apply now to join Alpha Hub's Founder's Circle Program and be part of a select group of industry leaders driving innovation in the investment sector. Your expertise, feedback, and insights will make a lasting impact on the platform, ensuring it meets the needs of professionals like you. 
                  </p>
              </div>
              <div className='p-10'>
                <button type="button" className="bg-dark" onClick={handleFormModalOpen}>Apply Now</button>
              </div>
          </article>
          {/* Render the FormModal */}
          <FormModal isOpen={isFormModalOpen} onClose={closeFormModal} />
      </section>
  )
}
