import React,{useState} from 'react'
import { FormModal } from "./ModalForm";

export default function Section1() {
  const [isFormModalOpen, setFormModalOpen] = useState(false);
  const handleFormModalOpen = () => {
    setFormModalOpen(true)
  }

  const closeFormModal = () => {
    setFormModalOpen(false)
  }
  return (
    <section className={`container flex flex-row justify-center items-center h-auto`}>
          <article className='flex flex-col items-center pb-8 w-full'>
              <h2 className={` text-[36px] mb-4 p-10 text-center `}>Program Overview</h2>
              <div className='max-w-[1000px] h-auto text-center min-h-[350px] lg:text-left text-[17px]'>
                  <p>
                  At Alpha Hub, we’re committed to building the future of investment technology, and we need your expertise to make it happen. The Founder’s Circle Program invites beta testers to play a crucial role in refining the platform before its market launch. Participants will contribute by enhancing user experience, prioritizing essential features, and identifying potential issues. In return, they will receive valuable benefits, including a year of free platform access post-beta and an invitation to join the Advisory Board or User Group. This ongoing involvement allows firms to directly influence the development of features aligned with their specific investment strategies, ensuring that the final product is optimized for their needs. By fostering this collaborative approach, Alpha Hub aims to create a platform that precisely addresses the evolving demands of its users while maintaining a strong connection with its early adopters. 
                  </p>
              </div>
              <div className='p-10'>
                <button type="button"  
                className="apply py-2 px-4 bg-[#EB5A3A] text-white flex items-center justify-center sm:w-fit rounded sm:ml-4" 
                            style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
                            onClick={handleFormModalOpen}>Apply Now</button>
                {/* Render the FormModal */}
                <FormModal isOpen={isFormModalOpen} onClose={closeFormModal} />
              </div>
          </article>
      </section>
  )
}
