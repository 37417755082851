import React from 'react'


export default function Section2() {
  
  return (
    <section className={` flex flex-row justify-center items-center h-aut bg-lightblue`}>
          <article className='flex flex-col items-center pb-8 w-full'>
              <h2 className={` text-[36px] mb-4 p-10 font-bold text-center `}>Incentives for Participation</h2>
              <div className='h-auto'>
                  <img src="incentive.svg" alt="" />
              </div>
              <div className='container flex flex-col pt-10'>
                <h2 className='text-[36px] mb-4 p-10 font-bold text-center'>Program Structure</h2>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  
                  {/* First Column */}
                  <div className="p-6 flex flex-col items-center">
                    <img src="phase1.svg" alt="Icon 1" className="mb-4" /> {/* Image for Column 1 */}
                    <h3 className="text-l font-semibold mb-2">1. User Research - Phase 1 </h3>
                    <p className="max-w-[275px] text-m text-center">We’ll begin by understanding your needs, pain points, and workflows related to deal sourcing. Your input will inform the product’s design and development, ensuring it addresses real-world challenges. </p>
                  </div>

                  {/* Second Column */}
                  <div className="p-6 flex flex-col items-center">
                    <img src="phase2.svg" alt="Icon 2" className="mb-4" /> {/* Image for Column 2 */}
                    <h3 className="text-l font-semibold mb-2">2. Usability Testing - Phase 2 </h3>
                    <p className="max-w-[275px] text-m text-center">Next, we’ll evaluate the platform’s ease of use, navigation, and overall user experience. You’ll have the opportunity to test prototype versions or specific features, providing feedback that will help us enhance usability. </p>
                  </div>

                  {/* Third Column */}
                  <div className="p-6 flex flex-col items-center">
                    <img src="phase3.svg" alt="Icon 3" className="mb-4" /> {/* Image for Column 3 */}
                    <h3 className="text-l font-semibold mb-2">3. Beta Testing - Phase 3 </h3>
                    <p className="max-w-[275px] text-m text-center">Finally, we’ll test the platform’s functionality, stability, and performance in a real-world environment. Your feedback during this phase will be critical in refining the product before its official launch. </p>
                  </div>

                </div>

              </div>
          </article>
          
      </section>
  )
}
