import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faTwitter, faInstagram  } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faMapMarker } from '@fortawesome/free-solid-svg-icons';
import {  ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Footer() {
  return (
    <footer className="footer  p-4  bottom-0 w-full flex flex-wrap justify-between">
          <div className="footer-column">
            <div className="logo-container">
              <img src="/alpha-hub.png" alt="Logo" className="footer-logo" />
            </div>
          </div>
          <div className="footer-column">
            <div className="footer-info">
              <div className="footer-info-item">
                <FontAwesomeIcon icon={faMapMarker} className="info-icon" />
                <span>99 Wall Street Suite 5895 <br/> New York, NY 10005</span>
              </div>
              <div className="footer-info-item">
                <FontAwesomeIcon icon={faPhone} className="info-icon" />
                <span>+1(833)930-2161</span>
              </div>
              <div className="footer-info-item">
                <FontAwesomeIcon icon={faEnvelope} className="info-icon" />
                <span>capital@konzortia.capital</span>
              </div>
            </div>
          </div>
          <div className="footer-column">
            <div className="social-icons-container">
              <div className="follow-us-icons">
                <h3 className="follow-us">Follow Us</h3> {/* Follow Us label */}
                <div className="social-icons">
                  <a href='https://www.linkedin.com/company/alphahub-kc/' aria-label="linkedin"  target="_blank" rel="noopener noreferrer" className="text-naranja-2 cursor-pointer hover:text-gray-700">
                    <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
                  </a>
                  <a target="_blank" rel="noopener noreferrer" aria-label="X" href='https://x.com/alphahub_ai' className="ml-4 text-naranja-2 cursor-pointer hover:text-gray-700">
                     <FontAwesomeIcon icon={faTwitter} className="social-icon" />
                  </a>
                  <a href='https://www.instagram.com/alphahub.ai/' aria-label="instagram" target="_blank" rel="noopener noreferrer" className="ml-4 text-naranja-2 cursor-pointer hover:text-gray-700">
                    <FontAwesomeIcon icon={faInstagram} className="social-icon" />
                  </a>
                  <a href='https://www.facebook.com/alphahubai ' aria-label="facebook" target="_blank" rel="noopener noreferrer" className="ml-4 text-naranja-2 cursor-pointer hover:text-gray-700">
                    <FontAwesomeIcon icon={faFacebook} className="social-icon" />
                  </a>
                    
                    
                    
                    
                </div>
              </div>
              <button  className="social-button relative flex w-full items-center justify-center text-white py-2.5 px-5 rounded-lg mt-2.5 tracking-button bg-[#0A1E35]"
                                    style={{ textShadow: '2px 2px 4px rgba(255, 255, 255, 0.5)' }}  onClick={() => { window.open('https://alpha-hub.ai/free-trial') }}>EARLY ACCESS</button> {/* Button under the icons */}
            </div>
          </div>
           {/* Toast Container */}
           <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
      </footer>
  )
}
