import React from 'react'


export default function Participants() {
  
  return (
    <section className="container flex flex-row justify-center items-center h-auto">
      <article className="flex flex-col items-center pb-8 w-full">
        <h2 className="text-[36px] mb-4 p-10 font-bold text-center">Participant Selection Criteria</h2>

        {/* Grid for the 4 divs */}
        <div className="container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 w-full max-w-4xl">
          {/* First Column, First Row */}
          <div className="flex flex-col items-center  p-4 border rounded-lg shadow bg-grey">
            <img src="expertise.svg" alt="Icon 1" className="mb-2" /> {/* Replace with your icon */}
            <h3 className="text-xl font-semibold mb-1">Expertise</h3>
            <p className="text-center ">We’re seeking participants with a strong background in venture capital, private equity, or other relevant investment sectors. Your knowledge and experience will be crucial in shaping the platform.</p>
          </div>

          {/* Second Column, First Row */}
          <div className="flex flex-col items-center  p-4 border rounded-lg shadow bg-grey">
            <img src="investment.svg" alt="Icon 2" className="mb-2" /> {/* Replace with your icon */}
            <h3 className="text-xl font-semibold mb-1">Investment Groups</h3>
            <p className="text-center">To ensure our platform meets a wide range of needs, we’re targeting various venture capital investment firms, including Family Offices, VC firms, PE firms, M&A firms, Angel Investors, and Investment Bankers.</p>
          </div>

          {/* First Column, Second Row */}
          <div className="flex flex-col items-center  p-4 border rounded-lg shadow bg-grey">
            <img src="engagement.svg" alt="Icon 3" className="mb-2" /> {/* Replace with your icon */}
            <h3 className="text-xl font-semibold mb-1">Engagement Level</h3>
            <p className="text-center">We prioritize firms willing to commit the time to provide detailed feedback and participate in follow-up sessions. Your engagement will be key to the program’s success.</p>
          </div>

          {/* Second Column, Second Row */}
          <div className="flex flex-col items-center  p-4 border rounded-lg shadow bg-grey">
            <img src="security.svg" alt="Icon 4" className="mb-2" /> {/* Replace with your icon */}
            <h3 className="text-xl font-semibold mb-1">Data Security</h3>
            <p className="text-center">We prioritize the protection of your sensitive information. Our robust security measures safeguard your data, ensuring it's handled with the highest level of confidentiality and care. </p>
          </div>
        </div>
      </article>
    </section>

  )
}
